import { useQuery } from '@tanstack/react-query';

import { getCategories } from '@/services/apis/category';
import { Category } from '@/models/category';

interface UseCategoriesParams {
  params?: {
    'sort[0]'?: string;
    [key: string]: any;
  };
}

interface UseCategoriesReturn {
  categories: Category[];
  isLoading: boolean;
  isError: boolean;
  error: Error | null;
  refetch: () => Promise<any>;
}

const useCategories = ({ params }: UseCategoriesParams = {}): UseCategoriesReturn => {
  const { data, isLoading, isError, error, refetch } = useQuery({
    queryKey: ['categories', params],
    queryFn: () =>
      getCategories({
        params: {
          'sort[0]': 'createdAt:DESC',
          ...params,
        },
      }),
    staleTime: 10 * 1000,
  });

  return {
    categories: (data ?? []) as Category[],

    isLoading,
    isError,
    error,
    refetch,
  };
};

export default useCategories;
