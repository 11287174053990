'use client';

import { Extensions } from '@tiptap/core';
import { StarterKit } from '@tiptap/starter-kit';
// Marks
import { Highlight } from '@tiptap/extension-highlight';
import { Underline } from '@tiptap/extension-underline';
// Functionality
import { FocusClasses as Focus } from '@tiptap/extension-focus';
import { Placeholder } from '@tiptap/extension-placeholder';
import { TextAlign } from '@tiptap/extension-text-align';
import { Youtube } from '@tiptap/extension-youtube';

import { Document } from './Document';
import { Heading } from './Heading';
import { HorizontalRule } from './HorizontalRule';
import { ImageBlock } from './ImageBlock';
import { ImageUpload } from './ImageUpload';
import { Link } from './Link';
import { Selection } from './Selection';
import { SlashCommand } from './SlashCommand';
import { EmbedEditor } from './EmbedEditor';
import { TrailingNode } from './TrailingNode';
import { Table, TableCell, TableHeader, TableRow } from './Table';
import { Column, Columns } from './MultiColumn';
import { ImageGrid } from './ImageGrid';
import { GalleryGrid } from './GalleryGrid';
import { GalleryUpload } from './GalleryCodeUpload';

export const ExtensionKit: Extensions = [
  Document,
  Columns,
  Column,
  StarterKit.configure({
    // Nodes
    document: false,
    codeBlock: false,
    hardBreak: false,
    heading: false,
    paragraph: {
      HTMLAttributes: {
        class: 'my-3 leading-relaxed first:mt-0 last:mb-0',
      },
    },
    blockquote: {
      HTMLAttributes: {
        class: 'border-l-4 border-gray-300 pl-4',
      },
    },
    horizontalRule: false,
    listItem: {
      HTMLAttributes: {
        class: 'my-2',
      },
    },
    bulletList: {
      HTMLAttributes: {
        class: 'list-disc my-4 px-8 py-0 first:mt-0 last:mb-0',
      },
    },
    orderedList: {
      HTMLAttributes: {
        class: 'list-outside list-decimal my-4 px-8 py-0 first:mt-0 last:mb-0 text-primary',
      },
    },

    // Marks
    code: false,

    // Functionality
    history: {
      depth: 20,
    },
    dropcursor: {
      color: '#1781EC',
      width: 2,
    },
  }),
  SlashCommand,
  Selection,
  Focus,
  TrailingNode,

  // Nodes
  ImageGrid,
  ImageUpload,

  ImageBlock,

  GalleryGrid,
  GalleryUpload,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  Heading,
  HorizontalRule,

  EmbedEditor,
  Youtube.configure({
    nocookie: true,
    HTMLAttributes: {
      class: 'w-full aspect-video',
    },
  }),

  // -------------------------

  // Marks
  Underline,
  Highlight,
  Link.configure({
    openOnClick: false,
    HTMLAttributes: {
      class:
        '!underline text-slate-500 dark:!text-slate-400 [&>*]:!text-slate-500 dark:[&>*]:!text-slate-400',
    },
    validate: (href) => /^https?:\/\//.test(href),
  }),

  // -------------------------

  // Functionality
  TextAlign.extend({
    addKeyboardShortcuts() {
      return {};
    },
  }).configure({
    types: ['heading', 'paragraph'],
  }),
  Placeholder.configure({
    includeChildren: true,
    showOnlyCurrent: false,
    placeholder: () => '',
  }),
];
