import { Node, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import GalleryGridView from './view/GalleryGridView';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    galleryGrid: {
      setGalleryGrid: (attributes: {
        urls: string[];
        title?: string;
        author?: string;
        date?: string;
        userDataUrl?: string;
        total: number;
      }) => ReturnType;
    };
  }
}

export const GalleryGrid = Node.create({
  name: 'galleryGrid',

  group: 'block',

  draggable: true,

  defining: true,

  isolating: true,

  addAttributes() {
    return {
      urls: {
        default: [],
        parseHTML: (element) =>
          Array.from(element.querySelectorAll('img')).map((img) => img.getAttribute('src')),
      },
      'data-type': {
        default: this.name,
      },
      title: {
        default: '',
      },
      author: {
        default: '',
      },
      date: {
        default: '',
      },
      userDataUrl: {
        default: '',
      },
      total: {
        default: 0,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
        getAttrs: (element) => {
          if (!(element instanceof HTMLElement)) {
            return {};
          }

          const parent = element.closest('a');
          const title = element.querySelector('h5')?.textContent || '';
          const author = element.querySelector('.text-gray-600')?.textContent || '';
          const date = element.querySelectorAll('.text-gray-600')[1]?.textContent || '';
          const userDataUrl = parent?.getAttribute('href') || '';
          const images = Array.from(element.querySelectorAll('img'));
          const urls = images.map((img) => img.getAttribute('src')).filter(Boolean) as string[];
          const totalText = element.querySelector('.bg-black\\/50')?.textContent || '';
          const total = totalText
            ? parseInt(totalText.replace('+', '')) + urls.length
            : urls.length;

          return {
            urls,
            title,
            author,
            date,
            userDataUrl,
            total,
          };
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const urls = HTMLAttributes?.urls || [];
    const title = HTMLAttributes?.title || 'title test';
    const author = HTMLAttributes?.author || 'author test';
    const date = HTMLAttributes?.date || 'date test';
    const userDataUrl = HTMLAttributes?.userDataUrl || '';
    const total = HTMLAttributes?.total || 0;

    return [
      'a',
      {
        href: userDataUrl,
        target: '_blank',
        rel: 'noopener noreferrer',
      },
      [
        'div',
        mergeAttributes(this.options.HTMLAttributes, {
          'data-type': this.name,
          class: 'bg-gray-50 p-4',
        }),
        [
          'div',
          { class: 'grid grid-cols-5 gap-2 relative' },
          ...urls.map((url: string, index: number) => [
            'div',
            { class: 'relative' },
            [
              'img',
              {
                src: url,
                alt: url,
                class: 'h-full w-full object-cover',
                'data-type': this.name,
              },
            ],
            ...(index === urls.length - 1 && total > 10
              ? [
                  [
                    'div',
                    {
                      class:
                        'absolute inset-0 z-20 flex items-center justify-center bg-black/50 text-2xl font-medium font-semibold text-white-100',
                    },
                    `+${total - urls.length}`,
                  ],
                ]
              : []),
          ]),
        ],
        [
          'div',
          { class: 'mt-4 flex items-center justify-between' },
          [
            'div',
            {},
            ['h5', { class: 'heading-h6 mb-1' }, title],
            [
              'div',
              { class: 'flex items-center gap-2' },
              [
                'div',
                {
                  class:
                    'avatar bg-gradient-to-br from-[#FFB457] to-[#FF705B] rounded-[50%] w-4 h-4 text-xs flex items-center justify-center',
                },
                author.charAt(0),
              ],
              ['span', { class: 'text-xs text-gray-600' }, author],
              ['div', { class: 'mx-1 h-[0.25rem] w-[0.25rem] rounded-[50%] bg-gray-300' }],
              [
                'div',
                { class: 'flex items-center gap-1' },
                [
                  'svg',
                  {
                    xmlns: 'http://www.w3.org/2000/svg',
                    width: '16',
                    height: '17',
                    viewBox: '0 0 16 17',
                    fill: 'none',
                  },
                  [
                    'path',
                    {
                      d: 'M7.9987 15.1667C11.6806 15.1667 14.6654 12.1819 14.6654 8.50004C14.6654 4.81814 11.6806 1.83337 7.9987 1.83337C4.3168 1.83337 1.33203 4.81814 1.33203 8.50004C1.33203 12.1819 4.3168 15.1667 7.9987 15.1667Z',
                      stroke: '#62758D',
                      'stroke-width': '1.5',
                      'stroke-linecap': 'round',
                      'stroke-linejoin': 'round',
                    },
                  ],
                  [
                    'path',
                    {
                      d: 'M8 4.5V8.5L10.6667 9.83333',
                      stroke: '#62758D',
                      'stroke-width': '1.5',
                      'stroke-linecap': 'round',
                      'stroke-linejoin': 'round',
                    },
                  ],
                ],
                ['span', { class: 'text-xs text-gray-600' }, date],
              ],
            ],
          ],
          [
            'div',
            {},
            [
              'svg',
              {
                xmlns: 'http://www.w3.org/2000/svg',
                width: '24',
                height: '25',
                viewBox: '0 0 24 25',
                fill: 'none',
              },
              [
                'path',
                {
                  d: 'M11 4.5H4C3.46957 4.5 2.96086 4.71071 2.58579 5.08579C2.21071 5.46086 2 5.96957 2 6.5V20.5C2 21.0304 2.21071 21.5391 2.58579 21.9142C2.96086 22.2893 3.46957 22.5 4 22.5H18C18.5304 22.5 19.0391 22.2893 19.4142 21.9142C19.7893 21.5391 20 21.0304 20 20.5V13.5',
                  stroke: '#62758D',
                  'stroke-width': '2',
                  'stroke-linecap': 'round',
                  'stroke-linejoin': 'round',
                },
              ],
              [
                'path',
                {
                  d: 'M18.5 2.99998C18.8978 2.60216 19.4374 2.37866 20 2.37866C20.5626 2.37866 21.1022 2.60216 21.5 2.99998C21.8978 3.39781 22.1213 3.93737 22.1213 4.49998C22.1213 5.06259 21.8978 5.60216 21.5 5.99998L12 15.5L8 16.5L9 12.5L18.5 2.99998Z',
                  stroke: '#62758D',
                  'stroke-width': '2',
                  'stroke-linecap': 'round',
                  'stroke-linejoin': 'round',
                },
              ],
            ],
          ],
        ],
      ],
    ];
  },

  addCommands() {
    return {
      setGalleryGrid:
        (attrs) =>
        ({ commands }) => {
          return commands.insertContent({
            type: 'galleryGrid',
            attrs: {
              urls: attrs.urls,
              title: attrs.title,
              author: attrs.author,
              date: attrs.date,
              userDataUrl: attrs.userDataUrl,
              total: attrs.total,
            },
          });
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(GalleryGridView);
  },
});

export default GalleryGrid;
