import React, { useCallback, useEffect, useRef } from 'react';
import { EmblaCarouselType, EmblaEventType, EmblaOptionsType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import { Button } from '@nextui-org/button';
import { CornersOut } from '@phosphor-icons/react';
import { Modal, ModalContent, ModalBody, useDisclosure } from '@nextui-org/modal';
import Image from 'next/image';
import { cn } from '@nextui-org/theme';

import { Slide } from './UploadGallery';

import FooterCarousel from '@/app/(main)/gallery/models/[slug]/_components/ModelDetailComponents/FooterCarousel';
import { ChevronRightSvg } from '@/assets/icons';

const TWEEN_FACTOR_BASE = 0.2;

type PropType = {
  slides: Slide[];
  options?: EmblaOptionsType;
  layout: 'carousel' | 'thumbnail' | 'grid';
  isPreviewOnly?: boolean;
};

const GalleryCarousel: React.FC<PropType> = (props) => {
  const { slides, options, isPreviewOnly = false } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const [emblaFooterRef, emblaFooterApi] = useEmblaCarousel(options);
  const tweenFactor = useRef(0);
  const tweenNodes = useRef<HTMLElement[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedImage, setSelectedImage] = React.useState<string>('');
  const [currentlySelected, setCurrentlySelected] = React.useState<number>(0);

  //   const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi);

  //   const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } =
  //     usePrevNextButtons(emblaApi);

  const setTweenNodes = useCallback((emblaApi: EmblaCarouselType): void => {
    tweenNodes.current = emblaApi.slideNodes().map((slideNode) => {
      return slideNode.querySelector('#layer') as HTMLElement;
    });
  }, []);

  const setTweenFactor = useCallback((emblaApi: EmblaCarouselType) => {
    tweenFactor.current = TWEEN_FACTOR_BASE * emblaApi.scrollSnapList().length;
  }, []);

  const tweenParallax = useCallback((emblaApi: EmblaCarouselType, eventName?: EmblaEventType) => {
    const engine = emblaApi.internalEngine();
    const scrollProgress = emblaApi.scrollProgress();
    const slidesInView = emblaApi.slidesInView();
    const isScrollEvent = eventName === 'scroll';

    emblaApi.scrollSnapList().forEach((scrollSnap, snapIndex) => {
      let diffToTarget = scrollSnap - scrollProgress;
      const slidesInSnap = engine.slideRegistry[snapIndex];

      slidesInSnap.forEach((slideIndex) => {
        if (isScrollEvent && !slidesInView.includes(slideIndex)) return;

        if (engine.options.loop) {
          engine.slideLooper.loopPoints.forEach((loopItem) => {
            const target = loopItem.target();

            if (slideIndex === loopItem.index && target !== 0) {
              const sign = Math.sign(target);

              if (sign === -1) {
                diffToTarget = scrollSnap - (1 + scrollProgress);
              }
              if (sign === 1) {
                diffToTarget = scrollSnap + (1 - scrollProgress);
              }
            }
          });
        }

        const translate = diffToTarget * (-1 * tweenFactor.current) * 100;
        const tweenNode = tweenNodes.current[slideIndex];

        // console.log(tweenNode, 'check');

        tweenNode.style.transform = `translateX(${translate}%)`;
      });
    });
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    setTweenNodes(emblaApi);
    setTweenFactor(emblaApi);
    tweenParallax(emblaApi);

    emblaApi
      .on('reInit', setTweenNodes)
      .on('reInit', setTweenFactor)
      .on('reInit', tweenParallax)
      .on('scroll', tweenParallax)
      .on('slideFocus', tweenParallax);
  }, [emblaApi, tweenParallax]);

  const handleImageExpand = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    onOpen();
  };

  const handleSelectPicture = useCallback(
    (index: number) => {
      if (emblaApi) {
        emblaApi.scrollTo(index);
        setCurrentlySelected(index);
      }
    },
    [emblaApi, emblaFooterApi],
  );

  const [prevBtnDisabled, setPrevBtnDisabled] = React.useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = React.useState(true);

  const onSelect = React.useCallback(() => {
    if (!emblaApi) return;
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, [emblaApi]);

  React.useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    emblaApi.on('select', onSelect);
    emblaApi.on('reInit', onSelect);
  }, [emblaApi, onSelect]);

  const scrollPrev = React.useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
  const scrollNext = React.useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);

  return (
    <>
      {props.layout != 'grid' && (
        <div
          className={cn('relative mx-auto [--slide-spacing:1rem]', {
            'max-w-[64rem] [--slide-height:31.1rem] [--slide-size:100%]': !isPreviewOnly,
            'max-w-full [--slide-height:44rem] [--slide-size:100%]': isPreviewOnly,
          })}
        >
          <div ref={emblaRef} className='overflow-hidden'>
            <div className='-ml-[var(--slide-spacing)] flex touch-pan-y'>
              {slides.map((item, index) => (
                <div
                  key={item.id}
                  className='translate-3d-0 min-w-0 flex-[0_0_var(--slide-size)] transform pl-[var(--slide-spacing)]'
                >
                  <div className='h-full overflow-hidden'>
                    <div className='relative flex h-full w-full justify-center' id='layer'>
                      <div className='relative h-[var(--slide-height)] w-full'>
                        <Image
                          fill
                          alt='Your alt text'
                          className='object-cover'
                          priority={index === 0}
                          sizes='(max-width: 768px) 100vw, (max-width: 1200px) 100vw, 100vw'
                          src={item.image.url}
                        />
                      </div>

                      <Button
                        isIconOnly
                        className='absolute bottom-4 right-4 z-10 bg-gray-900/20 text-white-50 hover:bg-gray-900/40'
                        radius='none'
                        size='lg'
                        onClick={() => handleImageExpand(item.image.url)}
                      >
                        <CornersOut size={32} />
                      </Button>

                      <div className='absolute bottom-4 left-4 bg-gray-900/20 p-[0.62rem] text-white-50'>
                        {index + 1} / {slides.length}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className=''>
            <Button
              isIconOnly
              className='absolute left-8 top-1/2 flex h-10 w-10 -translate-y-1/2 items-center justify-center bg-[rgba(73,73,73,0.50)] text-gray-700 disabled:text-gray-400'
              disabled={prevBtnDisabled}
              radius='none'
              size='md'
              onClick={scrollPrev}
            >
              <ChevronRightSvg
                className={`${prevBtnDisabled ? 'text-gray-400' : 'text-neutral-white'} rotate-180`}
                width='1.5rem'
              />
            </Button>

            <Button
              isIconOnly
              className='absolute right-8 top-1/2 flex h-10 w-10 items-center justify-center bg-[rgba(73,73,73,0.50)] text-gray-700 disabled:text-gray-400'
              disabled={nextBtnDisabled}
              radius='none'
              size='md'
              onClick={scrollNext}
            >
              <ChevronRightSvg
                className={`${nextBtnDisabled ? 'text-gray-400' : 'text-neutral-white'} `}
                width='1.5rem'
              />
            </Button>
          </div>
        </div>
      )}

      {props.layout === 'grid' && (
        <div className='grid w-full grid-cols-3 gap-2'>
          {slides.map((item) => (
            <div key={item.id} className='relative aspect-square'>
              <Image
                fill
                alt='Your alt text'
                className='object-cover'
                sizes='(max-width: 768px) 33vw, (max-width: 1200px) 33vw, 33vw'
                src={item.image.url}
              />
            </div>
          ))}
          s
        </div>
      )}

      <Modal
        hideCloseButton
        classNames={{
          backdrop: 'bg-black/90',
          base: 'bg-transparent',
          body: 'p-0',
        }}
        isOpen={isOpen}
        radius='none'
        size='full'
        onClose={onClose}
      >
        <ModalContent>
          <ModalBody className='relative flex items-center justify-center bg-transparent p-0'>
            <button className='absolute inset-0' title='Close modal' onClick={onClose} />

            <div className='relative h-[80vh] w-[70vw] max-w-screen-2xl'>
              <Image
                fill
                priority
                alt='Detailed view'
                className='object-contain'
                sizes='70vw'
                src={selectedImage}
              />
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>

      {props.layout === 'thumbnail' && (
        <FooterCarousel
          isGallery
          currentlySelected={currentlySelected}
          emblaApi={emblaFooterApi}
          emblaRef={emblaFooterRef}
          handleSelectPicture={handleSelectPicture}
          isPreview={isPreviewOnly}
          slides={slides}
        />
      )}
    </>
  );
};

export default GalleryCarousel;
