export type SiteConfig = typeof SITES;

export const SITES = {
  name: 'Bimmer Head Vietnam',
  description: 'Bimmer Head Vietnam is a community for BMW enthusiasts in Vietnam.',
  items: [
    { label: 'Guide', href: '/guides', isFeaturePublished: true },
    { label: 'Review', href: '/reviews', isFeaturePublished: true },
    { label: 'News', href: '/news', isFeaturePublished: true },
    { label: 'E-Magazine', href: '/e-magazines', isFeaturePublished: true },
    {
      label: 'Gallery',
      href: 'https://xenforo.bimmerhead.net/index.php?media/',
      isFeaturePublished: true,
    },
    // { label: 'Gallery', href: '/gallery', isFeaturePublished: true },
    { label: 'Models', href: '/models', isFeaturePublished: false },
    { label: 'Store', href: '/store', isFeaturePublished: false },
  ],
};
