import { RichText, Quote, Image, Gallery } from '@/components';
import {
  Content,
  ContentGallery,
  ContentImage,
  ContentQuote,
  ContentRichText,
} from '@/models/article';

export const CONTENT_PREFIX = 'content-blocks';

export const articleRenderer = (section: Content, className?: string) => {
  switch (section.__component) {
    case `${CONTENT_PREFIX}.rich-text`:
      return <RichText className={className} content={(section as ContentRichText).content} />;
    case `${CONTENT_PREFIX}.quote`:
      return (
        <Quote
          className={className}
          quote={(section as ContentQuote).quote}
          quoter={(section as ContentQuote).quoter}
        />
      );
    case `${CONTENT_PREFIX}.image`:
      return (
        <Image
          caption={(section as ContentImage).caption}
          className={className}
          height={(section as ContentImage).image?.height ?? undefined}
          imageUrl={(section as ContentImage).image?.url ?? undefined}
          width={(section as ContentImage).image?.width ?? undefined}
        />
      );
    case `${CONTENT_PREFIX}.gallery`:
      return (
        <Gallery
          caption={(section as ContentGallery).caption}
          className={className}
          images={
            (section as ContentGallery).images?.map((image) => ({
              id: image.id,
              url: image.formats?.medium?.url,
            })) ?? []
          }
        />
      );
    default:
      return null;
  }
};
