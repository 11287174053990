'use client';

import { cn } from '@nextui-org/theme';
import { Hash } from '@phosphor-icons/react/dist/ssr';
import { Editor, NodeViewWrapper } from '@tiptap/react';
import React, { useState, useEffect } from 'react';
import { useSession } from 'next-auth/react';
import { Spinner } from '@nextui-org/spinner';
import { Input } from '@nextui-org/input';
import { toast } from 'react-toastify';

import { UserAlbum } from '@/types/apis/model';
import { getXenforoAlbum, getXenforoAlbumDetail } from '@/services/apis/xenforo';

interface ImageUploadViewProps {
  getPos: () => number;
  editor: Editor;
}

export const GalleryUploadView: React.FC<ImageUploadViewProps> = ({ getPos, editor }) => {
  const [confirmedPhotos, setConfirmedPhotos] = useState<{ thumbnail_url: string }[]>([]);
  const { data: session } = useSession();

  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [userAlbum, setUserAlbum] = useState<UserAlbum | null>(null);
  const [total, setTotal] = useState(0);

  const [albumDetail, setAlbumDetail] = useState<{
    title: string;
    last_activity: number;
    view_url: string;
  } | null>(null);

  const extractAlbumId = (input: string): string | null => {
    // Match [GALLERY=album, {id}] format
    const galleryMatch = input.match(/\[GALLERY=album,\s*(\d+)\]/i);

    if (galleryMatch) return galleryMatch[1];

    // Match URL format containing /albums/{name}.{id}/
    const urlMatch = input.match(/\/albums\/.*?\.(\d+)\//);

    if (urlMatch) return urlMatch[1];
    // Match direct URL format
    // Match direct number input
    const numberMatch = input.match(/^(\d+)$/);

    if (numberMatch) return numberMatch[1];

    return null;
  };

  const handleSubmit = async () => {
    try {
      setIsUploading(true);
      setError(null);

      const albumId = extractAlbumId(inputValue);

      if (!albumId) {
        setError('Invalid album format');
        toast.error('Invalid album format');

        return;
      }

      const data = await getXenforoAlbum(albumId);
      const albumDetail = await getXenforoAlbumDetail(albumId);
      const album = data.media.map((item) => ({
        thumbnail_url: item.thumbnail_url,
      }));

      // console.log(albumDetail, '@album');

      if (albumDetail) {
        setAlbumDetail({
          title: albumDetail.album.title,
          last_activity: albumDetail.album.create_date || 0,
          view_url:
            albumDetail.album.view_url.replace(
              'http://localhost/BE/bmw-forum',
              'https://xenforo.bimmerhead.net',
            ) || '',
        });
      }

      if (album) {
        const transformedUrl = album.map((item) => ({
          ...item,
          thumbnail_url: item.thumbnail_url.replace(
            'http://localhost/BE/bmw-forum',
            'https://xenforo.bimmerhead.net',
          ),
        }));

        setTotal(data.pagination.total);

        setUserAlbum({
          avatar_urls: albumDetail.album.User?.avatar_urls || { o: '' },
          username: albumDetail.album.User?.username || '',
          last_activity: albumDetail.album.User?.last_activity || 0,
        });

        setConfirmedPhotos(transformedUrl);
        setInputValue('');
      } else {
        setError('No thumbnail found for this album');
        toast.error('No thumbnail found for this album');
      }
    } catch (error) {
      console.error('Error fetching gallery:', error);
      setError('Album not found or invalid code');
      toast.error('Album not found or invalid code');
    } finally {
      setIsUploading(false);
    }
  };

  useEffect(() => {
    if (confirmedPhotos.length > 0) {
      editor
        .chain()
        .setGalleryGrid({
          urls: [...confirmedPhotos.map((photo) => photo.thumbnail_url)],
          title: albumDetail?.title || '',
          author: userAlbum?.username || '',
          date: new Date(
            albumDetail?.last_activity ? albumDetail.last_activity * 1000 : 0,
          ).toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          }),
          userDataUrl: albumDetail?.view_url || '',
          total: total,
        })

        .deleteRange({ from: getPos(), to: getPos() })
        .focus()
        .run();

      console.log(total, 'total123');
    }
  }, [confirmedPhotos, editor, getPos]);

  return (
    <NodeViewWrapper>
      <div data-drag-handle className={cn('', 'relative')} contentEditable={false}>
        <Input
          isClearable
          className='bg-white border border-white-100'
          classNames={{
            input: [
              'bg-white hover:!bg-white focus:!bg-white',
              'text-ellipsis overflow-hidden whitespace-nowrap',
              'max-w-[45rem]',
            ].join(' '),
            inputWrapper: 'bg-white data-[hover=true]:bg-white group-data-[focus=true]:bg-white',
          }}
          endContent={
            isUploading ? (
              <Spinner color='primary' size='sm' />
            ) : (
              <button
                className={cn('cursor-pointer text-[1rem] text-primary hover:text-blue-400')}
                onClick={handleSubmit}
              >
                Done
              </button>
            )
          }
          isReadOnly={isUploading}
          placeholder='Paste or type a album link or album code'
          radius='none'
          startContent={<Hash className='text-gray-800' size={24} />}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && handleSubmit()}
        />
      </div>
    </NodeViewWrapper>
  );
};

export default GalleryUploadView;
