import { XenforoAlbum } from '@/types/apis/model';

interface XenforoAlbumResponse {
  media: XenforoAlbum[];
  pagination: {
    total: number;
  };
}

interface XenforoAlbumDetailResponse {
  album: XenforoAlbum;
}

export const getXenforoAlbum = async (albumId: string): Promise<XenforoAlbumResponse> => {
  try {
    const response = await fetch(`/api/media-albums/${albumId}/media`);

    if (!response.ok) {
      throw new Error(`Failed to fetch album: ${response.status} ${response.statusText}`);
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error('Error fetching Xenforo album:', error);
    throw new Error('Failed to fetch album data. Please try again later.');
  }
};

export const getXenforoAlbumDetail = async (
  albumId: string,
): Promise<XenforoAlbumDetailResponse> => {
  try {
    const response = await fetch(`/api/media-albums/${albumId}`);

    if (!response.ok) {
      throw new Error(`Failed to fetch album details: ${response.status} ${response.statusText}`);
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error('Error fetching Xenforo album details:', error);
    throw new Error('Failed to fetch album details. Please try again later.');
  }
};
