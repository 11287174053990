'use client';

import { Image } from '@nextui-org/image';
import { cn } from '@nextui-org/theme';
import { NotePencil, Clock } from '@phosphor-icons/react';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';

import { heading } from '@/theme/typography';

interface GalleryGridViewProps extends NodeViewProps {}

export const GalleryGridView: React.FC<GalleryGridViewProps> = ({ editor, getPos, node }) => {
  const { urls, title, author, date, userDataUrl, total } = node.attrs as Node & {
    urls: string[];
    title: string;
    author: string;
    date: string;
    userDataUrl: string;
    total: number;
  };

  return (
    <NodeViewWrapper>
      <div data-drag-handle>
        <a href={userDataUrl} rel='noopener noreferrer' target='_blank'>
          <div className='bg-gray-50 p-4'>
            <div className='grid grid-cols-5 gap-2'>
              {urls.map((url, index) => (
                <div key={url} className='relative'>
                  <Image alt={url} className='h-full w-full object-cover' radius='none' src={url} />

                  {total > 10 && index === urls.length - 1 && (
                    <div className='absolute inset-0 z-20 flex items-center justify-center bg-black/50 text-2xl font-medium font-semibold text-white-100'>
                      +{total - urls.length}
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className='mt-4 flex items-center justify-between'>
              <div>
                <h5 className={cn(heading({ size: 'h6' }), 'mb-1')}>{title}</h5>

                <div className='flex items-center gap-2'>
                  <div className='text-white flex h-4 w-4 items-center justify-center rounded-full bg-gradient-to-br from-[#FFB457] to-[#FF705B] text-xs'>
                    {author.charAt(0)}
                  </div>

                  <span className='text-xs text-gray-600'>{author}</span>

                  <div className='mx-1 h-[0.25rem] w-[0.25rem] rounded-[50%] bg-gray-300' />
                  <div className='flex items-center gap-1'>
                    <Clock size={16} />
                    <span className='text-xs text-gray-600'>{date}</span>
                  </div>
                </div>
              </div>

              <div>
                <NotePencil className='text-gray-600' size={24} />
              </div>
            </div>
          </div>
        </a>
      </div>
    </NodeViewWrapper>
  );
};

export default GalleryGridView;
