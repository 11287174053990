'use client';

import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Select, SelectItem, SelectProps } from '@nextui-org/select';
import { cn } from '@nextui-org/theme';
import { Select as SelectNextUI } from '@nextui-org/select';
import { X } from '@phosphor-icons/react';

import { body } from '@/theme/typography';

interface RHFSelectProps extends Omit<SelectProps, 'children'> {
  name: string;
  items: {
    key: string;
    label: string | number;
  }[];

  mode?: 'single' | 'multiple';
  size?: 'lg' | 'sm' | 'md' | undefined;
  radius?: 'none' | 'lg' | 'sm' | 'md' | 'full' | undefined;
  id?: string;
  selectedKeys?: Set<string> | string[];
  value?: any;
  onSelectionChange?: (keys: Set<string> | string[]) => void;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  isInvalid?: boolean;
  errorMessage?: string;
  placeholder?: string;
}

const RHFSelect: React.FC<RHFSelectProps> = ({
  name,
  classNames,
  mode = 'single',
  size,
  radius,
  id,
  selectedKeys,
  value,
  onSelectionChange,
  onChange,
  isInvalid,
  errorMessage,
  placeholder,
  ...others
}) => {
  const { control } = useFormContext<Record<string, string | number>>();

  return mode === 'single' ? (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange }, fieldState: { error }, formState: { disabled } }) => (
        <Select
          labelPlacement='outside'
          radius='none'
          size={size}
          variant='bordered'
          {...others}
          classNames={{
            ...classNames,
            trigger: cn('shadow-none', classNames?.trigger),
            label: cn(body({ weight: 'medium' }), classNames?.label),
            popoverContent: cn('rounded-none', classNames?.popoverContent),
          }}
          disabled={disabled}
          errorMessage={error?.message}
          isInvalid={!!error}
          selectedKeys={[value]}
          onChange={onChange}
        >
          {({ key, label }) => (
            <SelectItem
              key={key}
              showDivider
              className={cn(body())}
              variant='light'
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {label}
            </SelectItem>
          )}
        </Select>
      )}
    />
  ) : (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange }, fieldState: { error }, formState: { disabled } }) => (
        <SelectNextUI
          classNames={{
            base: 'w-full',
            trigger: cn(
              'bg-white shadow-none border rounded-none h-fit py-2',
              'hover:border-primary focus:border-primary',
              classNames?.trigger,
            ),
            value: 'text-gray-700',
            innerWrapper: 'text-gray-500 ',
            errorMessage: 'text-red-500',
            popoverContent: 'rounded-none',
            ...classNames,
          }}
          disabled={disabled}
          errorMessage={error?.message}
          isInvalid={!!error}
          placeholder={placeholder}
          radius={'none'}
          renderValue={(items) => (
            <div className='flex flex-wrap gap-2'>
              {items?.map((item) => (
                <div
                  key={item.key}
                  className='flex items-center gap-1 rounded-none border border-gray-200 px-2 py-1'
                >
                  <span className='text-sm capitalize'>{item.textValue}</span>
                  <X
                    className='h-3 w-3 cursor-pointer hover:text-red-500'
                    onClick={(e) => {
                      e.stopPropagation();
                      const newValue = (value as unknown as string[]).filter((v) => v !== item.key);

                      onChange(newValue);
                    }}
                  />
                </div>
              ))}
            </div>
          )}
          selectedKeys={value ? new Set(Array.isArray(value) ? value : [value]) : new Set()}
          selectionMode='multiple'
          size={size}
          variant='bordered'
          onSelectionChange={(keys) => {
            const selectedValues = Array.from(keys as Set<string>);

            onChange(selectedValues);
          }}
          {...others}
        >
          {others?.items?.map((item) => (
            <SelectItem
              key={item.key}
              className={cn(
                'data-[selected=true]:bg-primary/10',
                'data-[selected=true]:text-primary',
                'rounded-none data-[hover=true]:rounded-none',
                'capitalize',
              )}
              value={item.key}
            >
              {item.label}
            </SelectItem>
          ))}
        </SelectNextUI>
      )}
    />
  );
};

export default RHFSelect;
