'use client';

import { Avatar } from '@nextui-org/avatar';
import { Button } from '@nextui-org/button';
import { Divider } from '@nextui-org/divider';
import { cn } from '@nextui-org/theme';
import { format } from 'date-fns';
import * as React from 'react';
import { CalendarBlank } from '@phosphor-icons/react/dist/ssr';
import Link from 'next/link';
import useEmblaCarousel from 'embla-carousel-react';

import { useProgress } from './useProgress';

import { ArrowRightSvg } from '@/assets/icons';
import { FallbackImage, ThumbnailTags } from '@/components';
import { caption, heading } from '@/theme/typography';
import { Article } from '@/models/article';

interface CarouselProps {
  articles: Article[];
  className?: string;
  classNames?: {
    progress?: string;
    progressWrapper?: string;
  };
}

export interface CarouselRef {
  next: () => void;
  prev: () => void;
}

const Carousel = React.forwardRef<unknown, CarouselProps>(
  ({ articles, className, classNames }, ref) => {
    const [emblaRef, emblaApi] = useEmblaCarousel({
      align: 'center',
      containScroll: false,
      loop: true,
    });

    const { selectedIndex, scrollSnaps, onProgressClick } = useProgress(emblaApi);

    React.useImperativeHandle(ref, () => ({
      next: () => emblaApi?.scrollNext(),
      prev: () => emblaApi?.scrollPrev(),
    }));

    return (
      <div className={cn('flex flex-col gap-6', className)}>
        <div
          ref={emblaRef}
          className={cn('embla__viewport overflow-hidden')}
          style={{ maxWidth: `calc(80vh * ${articles.length})` }}
        >
          <div className='-ml-6 flex h-[400px] touch-pan-y touch-pinch-zoom flex-row md:h-[480px] xl:h-[560px]'>
            {articles.map(({ id, title, slug, categories, cover, createdAt, author }) => (
              <div
                key={id}
                className='shrink-0 grow-0 basis-3/4 pl-6'
                style={{ transform: 'translate3d(0, 0, 0)' }}
              >
                <Link key={id} className='relative h-full w-full' href={`/e-magazines/${slug}`}>
                  <div
                    className={cn(
                      'absolute bottom-0 left-0 z-20 flex w-full flex-col justify-between gap-4 p-8',
                      'text-neutral-white transition-all md:flex-row md:items-end',
                    )}
                  >
                    <div className='flex w-full flex-col gap-4 md:w-3/4'>
                      <ThumbnailTags
                        classNames={{ tag: 'text-neutral-white bg-neutral-white/20' }}
                        tags={categories?.map(({ id, name }) => ({ id, label: name }))}
                      />

                      <div className={cn(heading({ size: 'h4' }), 'line-clamp-3')}>{title}</div>

                      <div className='flex flex-row items-center gap-3 text-gray-50'>
                        <div className={cn(caption(), 'flex flex-row items-center gap-2')}>
                          <Avatar
                            className='h-7 w-7 border-2 border-gray-100'
                            size='sm'
                            src={author?.avatar?.url}
                          />
                          <span>{author?.name}</span>
                        </div>

                        <Divider className={cn('h-3 bg-neutral-white')} orientation='vertical' />

                        <div className={cn(caption(), 'flex flex-row items-center gap-2')}>
                          <CalendarBlank className='w-5' />
                          {format(createdAt, 'd MMM yyyy').toUpperCase()}
                        </div>
                      </div>
                    </div>

                    <Button
                      className='shrink-0 border-neutral-white text-neutral-white hover:bg-neutral-white hover:text-neutral-black hover:!opacity-100'
                      endContent={<ArrowRightSvg className='w-6' />}
                      radius='none'
                      size='lg'
                      variant='bordered'
                    >
                      Read Now
                    </Button>
                  </div>

                  <picture className='relative z-0 h-full w-full'>
                    <FallbackImage
                      alt="E-Magazine's cover"
                      className='h-full w-full object-cover'
                      height={600}
                      loading='lazy'
                      src={cover?.url ?? ''}
                      width={1100}
                    />
                  </picture>

                  <div className='absolute left-0 top-0 z-10 h-full w-full bg-gradient-to-t from-neutral-black opacity-75' />
                </Link>
              </div>
            ))}
          </div>
        </div>

        <div className='container'>
          <div
            className={cn('flex h-2 w-full flex-row bg-gray-50', classNames?.progressWrapper)}
            data-slot='progress-wrapper'
          >
            {scrollSnaps.map((_, index) => (
              <button
                key={index}
                aria-label={`Go to slide ${index + 1}`}
                className={cn(
                  'h-full grow transition-all hover:bg-gray-200 data-[active=false]:bg-transparent data-[active=true]:bg-neutral-black',
                  classNames?.progress,
                )}
                data-active={index === selectedIndex}
                data-slot='progress'
                tabIndex={-1}
                onClick={() => onProgressClick(index)}
              />
            ))}
          </div>
        </div>
      </div>
    );
  },
);

Carousel.displayName = 'Carousel';

export default Carousel;
