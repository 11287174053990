import { Avatar } from '@nextui-org/avatar';
import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/dropdown';
import * as React from 'react';
import { signOut } from 'next-auth/react';
import { User as NextAuthUser } from 'next-auth';
import { BookmarkSimple, CaretDown, SignOut, User } from '@phosphor-icons/react/dist/ssr';
import { cn } from '@nextui-org/theme';
import Link from 'next/link';

import { body } from '@/theme/typography';

interface ProfileDropdownProps {
  user: NextAuthUser;
}

const ProfileDropdown: React.FC<ProfileDropdownProps> = ({ user }) => (
  <Dropdown
    showArrow
    classNames={{
      base: 'before:bg-neutral-white', // Change arrow background
      content: 'p-0 border-none bg-background rounded-none',
    }}
    offset={20}
    placement='bottom-end'
    radius='sm'
    size='lg'
  >
    <DropdownTrigger>
      <button
        aria-label='Open profile menu'
        className='flex flex-row items-center gap-2 text-inherit outline-none'
      >
        <Avatar
          className='border-2 border-gray-100 transition-transform'
          size='sm'
          src={user.avatar?.url ?? ''}
        />
        <CaretDown className='hidden w-5 lg:block' />
      </button>
    </DropdownTrigger>

    <DropdownMenu aria-label='Profile Menu' className='p-3' variant='light'>
      <DropdownItem
        key='profile'
        showDivider
        as={Link}
        className={cn(body())}
        href={`/${user.username}`}
        startContent={<User size={24} />}
      >
        Profile
      </DropdownItem>

      <DropdownItem
        key='saved'
        showDivider
        as={Link}
        className={cn(body())}
        href='/saved'
        startContent={<BookmarkSimple size={24} />}
      >
        Saved
      </DropdownItem>

      {/* <DropdownItem
        key='settings'
        showDivider
        className={cn(body())}
        startContent={<Gear size={24} />}
      >
        Settings
      </DropdownItem> */}

      <DropdownItem
        key='logout'
        className={cn(body(), 'text-danger')}
        color='danger'
        startContent={<SignOut size={24} />}
        onClick={() => {
          signOut({ redirect: true, redirectTo: '/' });
        }}
      >
        Sign out
      </DropdownItem>
    </DropdownMenu>
  </Dropdown>
);

export default ProfileDropdown;
