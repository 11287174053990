import { Node, ReactNodeViewRenderer } from '@tiptap/react';
import { mergeAttributes } from '@tiptap/core';

import GalleryUploadView from './view/GalleryUploadView';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    galleryUpload: {
      setGalleryUpload: () => ReturnType;
      // setImageGrid: (attributes: { urls: string[] }) => ReturnType;
    };
  }
}

export const GalleryUpload = Node.create({
  name: 'galleryUpload',

  isolating: true,

  defining: true,

  group: 'block',

  draggable: true,

  selectable: true,

  inline: false,

  addAttributes() {
    return {
      urls: {
        default: [],
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        'data-type': this.name,
      }),
    ];
  },

  addCommands() {
    return {
      setGalleryUpload:
        () =>
        ({ commands }) =>
          commands.insertContent(`<div data-type="${this.name}"></div>`),

      setGalleryGrid:
        (attrs) =>
        ({ commands }) => {
          return commands.insertContent({
            type: 'galleryGrid',
            attrs: {
              urls: attrs.urls,
              title: attrs.title,
              author: attrs.author,
              date: attrs.date,
              userDataUrl: attrs.userDataUrl,
              total: attrs.total,
            },
          });
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(GalleryUploadView);
  },
});

export default GalleryUpload;
