'use client';

import { Button } from '@nextui-org/button';
import { cn } from '@nextui-org/theme';
import {
  ChatsCircle,
  DotsThree,
  Export,
  FacebookLogo,
  LinkedinLogo,
  LinkSimpleHorizontal,
  XLogo,
} from '@phosphor-icons/react/dist/ssr';
import * as React from 'react';
import { toast } from 'react-toastify';
import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/dropdown';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import { Eye } from '@phosphor-icons/react';

import { ReactButton } from '..';

import SavedButton from './SavedButton';

import { Article, ArticleDetail } from '@/models/article';
import { increaseArticleShares } from '@/services/apis/article';
import useArticleComments from '@/hooks/queries/article/useArticleComments';
import { humanizeNumber } from '@/utils/number';
import { caption } from '@/theme/typography';

interface IArticleActionsSectionProps {
  article: ArticleDetail;

  reactionCount?: number;
  commentCount?: number;

  onReact?: () => void;
  onComment?: () => void;
  isLiked?: boolean;
  className?: string;
  previewOnly?: boolean;
}

const ArticleActionsSection: React.FC<IArticleActionsSectionProps> = ({
  article,
  reactionCount = 0,
  commentCount = 0,
  onReact,
  onComment,
  isLiked,
  className,
  previewOnly = false,
}) => {
  const router = useRouter();
  const { data: session } = useSession();
  const { comments, isLoading } = useArticleComments(article.id);
  const handleShareSocial = async (href: string) => {
    await increaseArticleShares(article.id);
    window.open(href, '_blank', 'noopener,noreferrer');
  };

  const handleCopyLink = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    try {
      const url = `https://bimmerhead.net/guides/${article.slug}`;

      await navigator.clipboard.writeText(url);
      toast.success('Link copied to clipboard');
      await increaseArticleShares(article.id);
    } catch (error) {
      toast.error('Failed to copy link');
    }
  };

  const scrollToComments = () => {
    const comments = document.getElementById('comments');

    if (!comments) return;
    window.scroll({
      top: comments.offsetTop - 128,
      behavior: 'smooth',
    });

    onComment?.();
  };

  // console.log(article, 'check reaction count');

  return (
    <section
      className={cn(
        'relative flex flex-row items-center justify-between gap-6 border-y-1 border-y-divider py-2',
        className,
      )}
    >
      {previewOnly && <div className='absolute inset-0 z-20' />}

      <div className='flex flex-row items-center gap-2'>
        <ReactButton
          className='-ml-2 text-gray-600'
          count={reactionCount}
          currentId={article.id}
          currentType='article'
          isLiked={isLiked}
          radius='none'
          onClick={onReact}
        />

        <Button
          className={cn('flex w-auto min-w-0 flex-row items-center gap-2 px-2 text-gray-600')}
          radius='none'
          startContent={<ChatsCircle size={24} />}
          variant='light'
          onClick={scrollToComments}
        >
          {comments?.length}
        </Button>

        <div
          className={cn(
            caption({ weight: 'medium' }),
            'flex w-auto min-w-0 flex-row items-center gap-2 px-2 text-gray-600',
          )}
        >
          <Eye size={20} />
          {humanizeNumber(article.totalViews)}
        </div>
      </div>

      <div className='flex flex-row items-center gap-2'>
        {session && <SavedButton articleId={article.id} />}

        <Dropdown
          showArrow
          classNames={{
            content: 'border-none bg-background rounded-none',
          }}
          offset={10}
          placement='bottom-end'
          radius='sm'
          ria-label='Saved list actions'
        >
          <DropdownTrigger>
            <Button isIconOnly className='text-gray-600' radius='none' variant='light'>
              <Export size={24} />
            </Button>
          </DropdownTrigger>

          <DropdownMenu
            aria-label='Share article'
            items={[
              {
                key: 'copy-link',
                icon: <LinkSimpleHorizontal size={24} />,
                label: 'Copy link',
                onClick: (event: React.MouseEvent<HTMLElement>) => handleCopyLink(event),
              },
              {
                key: 'x',
                icon: <XLogo size={24} />,
                label: 'Share on X',
                onClick: () =>
                  handleShareSocial(
                    `https://twitter.com/intent/tweet?text=${article.title}&url=https://bimmerhead.net/news/${article.slug}`,
                  ),
              },
              {
                key: 'facebook',
                icon: <FacebookLogo size={24} />,
                label: 'Share on Facebook',
                onClick: () =>
                  handleShareSocial(
                    `https://www.facebook.com/sharer/sharer.php?u=https://bimmerhead.net/news/${article.slug}`,
                  ),
              },
              {
                key: 'linkedin',
                icon: <LinkedinLogo size={24} />,
                label: 'Share on LinkedIn',
                onClick: () =>
                  handleShareSocial(
                    `https://www.linkedin.com/shareArticle?mini=true&title=${article.title}&url=https://bimmerhead.net/news/${article.slug}`,
                  ),
                showDivider: false,
              },
            ]}
          >
            {({ key, icon, label, onClick, ...others }) => (
              <DropdownItem
                key={key}
                showDivider
                aria-label={label}
                startContent={icon}
                variant='light'
                onClick={onClick}
                {...others}
                className={cn('after:bg-gray-50')}
              >
                {label}
              </DropdownItem>
            )}
          </DropdownMenu>
        </Dropdown>

        {session && session.user.username === article.author?.username && (
          <Dropdown
            showArrow
            classNames={{
              content: 'border-none bg-background rounded-none',
            }}
            offset={10}
            placement='bottom-end'
            radius='sm'
            ria-label='Saved list actions'
          >
            <DropdownTrigger>
              <Button isIconOnly className='text-gray-600' radius='none' variant='light'>
                <DotsThree size={24} />
              </Button>
            </DropdownTrigger>

            <DropdownMenu
              aria-label='Article actions'
              items={[
                {
                  key: 'edit',
                  label: 'Edit',
                  onClick: () => {
                    router.push(`/publish/article/${article.id}`);
                  },
                },
                {
                  key: 'delete',
                  label: 'Delete',
                  onClick: () => {},
                  className: 'text-danger',
                  color: 'danger',
                  showDivider: false,
                },
              ]}
            >
              {({ key, label, onClick, color, ...others }) => (
                <DropdownItem
                  key={key}
                  showDivider
                  aria-label={label}
                  color={(color as 'default') ?? 'default'}
                  variant='light'
                  onClick={onClick}
                  {...others}
                >
                  {label}
                </DropdownItem>
              )}
            </DropdownMenu>
          </Dropdown>
        )}
      </div>
    </section>
  );
};

export default ArticleActionsSection;
