import { AxiosRequestConfig } from 'axios';

import { publicApi } from '@/config/api';
import { GetTagsApiResponse, Tag } from '@/types/apis/tag';

const SERVICE_PATH = '/tags';

export const getTags = async ({ params }: { params?: AxiosRequestConfig['params'] }) => {
  try {
    const { data: res } = await publicApi<GetTagsApiResponse>({
      method: 'GET',
      url: SERVICE_PATH,
      params,
    });

    return res.data.map(
      (tag) =>
        ({
          ...tag,
          createdAt: new Date(tag.createdAt),
          updatedAt: new Date(tag.updatedAt),
        }) as Tag,
    );
  } catch (error) {
    return null;
  }
};
