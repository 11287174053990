import { useQuery } from '@tanstack/react-query';

import { getTags } from '@/services/apis/tag';

const useTags = ({ params }: any) => {
  const { data, ...others } = useQuery({
    queryKey: ['tags', params],
    queryFn: () => getTags({ params }),
    staleTime: 10 * 1000,
    select: (data) => data,
  });

  return {
    tags: data,
    ...others,
  };
};

export default useTags;
