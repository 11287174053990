import { AxiosRequestConfig } from 'axios';

import { authApi, publicApi } from '@/config/api';
import {
  Article,
  ArticleDetail,
  ContentGallery,
  ContentImage,
  ContentQuote,
  ContentRichText,
} from '@/models/article';
import {
  ArticleProps,
  GetArticleBySlugApiResponse,
  GetArticlesApiResponse,
  GetArticleSavedListsApiResponse,
  GetArticleWithDraftByIdApiResponse,
} from '@/types/apis/article';
import { CONTENT_PREFIX } from '@/utils/article-renderer';
import { SavedList } from '@/models/saved-list';
import { Photo } from '@/types/apis/model';

const SERVICE_PATH = '/articles';

const CREATE_REQUIRE_DEFAULT = {
  status: 'Published',
  content: [],
  totalLikes: 0,
  total_shares: 0,
  totalViews: 0,
  trendingScore: 0,
  isFeatured: false,
  layoutType: 'long-form',
  type: {
    disconnect: [],
    connect: [],
  },
  tags: {
    disconnect: [],
    connect: [],
  },
  author: {
    disconnect: [],
    connect: [],
  },
  reportCollection: {
    disconnect: [],
    connect: [],
  },
  codename: {
    disconnect: [],
    connect: [],
  },
  title: 'Title must unique 2222  sx222xx2',
  description: 'any',
  cover: 223,
  // published: true,
};

export const ARTICLE_SLUGS = [
  { name: 'News', slug: 'news' },
  { name: 'Guide', slug: 'guides' },
  { name: 'Review', slug: 'reviews' },
  { name: 'E-Magazine', slug: 'e-magazines' },
];

const parseToArticle = (res: GetArticlesApiResponse) => {
  const articles = res.data.map((article) => ({
    ...article,
    content: article.content,
    createdAt: new Date(article.createdAt),
    updatedAt: new Date(article.updatedAt),
    publishedAt: new Date(article.publishedAt),
    ...(article.author
      ? {
          author: {
            ...article.author,
            ...(article.author.avatar
              ? {
                  avatar: {
                    ...article.author.avatar,
                    createdAt: new Date(article.author.avatar.createdAt),
                    updatedAt: new Date(article.author.avatar.updatedAt),
                  },
                }
              : {}),
            createdAt: new Date(article.author.createdAt),
            updatedAt: new Date(article.author.updatedAt),
          },
        }
      : {}),

    ...(article.type
      ? {
          type: {
            ...article.type,
            slug: ARTICLE_SLUGS.find((slug) => slug.name === article.type.name)?.slug ?? null,
            createdAt: new Date(article.type.createdAt),
            updatedAt: new Date(article.type.updatedAt),
            publishedAt: new Date(article.type.publishedAt),
          },
        }
      : {}),

    ...(article.cover
      ? {
          cover: {
            ...article.cover,
            createdAt: new Date(article.cover.createdAt),
            updatedAt: new Date(article.cover.updatedAt),
          },
        }
      : {}),
  })) as Article[];

  return articles;
};

export const getArticles = async ({
  params,
  withDraft,
}: {
  params?: AxiosRequestConfig['params'];
  withDraft?: boolean;
}) => {
  try {
    const { data: res } = await publicApi<GetArticlesApiResponse>({
      method: 'GET',
      url: `${SERVICE_PATH}${withDraft ? '?_withDraft=true' : ''}`,
      params,
    });

    return parseToArticle(res);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('`getArticles` API Error', error);

    return null;
  }
};

export const getArticlesByType = async ({
  type,
  params,
}: {
  type: string;
  params?: AxiosRequestConfig['params'];
}) => {
  try {
    const { data: res } = await publicApi<GetArticlesApiResponse>({
      method: 'GET',
      url: `${SERVICE_PATH}/${type}`,
      params,
    });

    return parseToArticle(res);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('`getArticlesByType` API Error', error);

    return null;
  }
};

export const getArticleBySlug = async (slug: string, token?: string) => {
  try {
    const api = token ? authApi(token) : publicApi;

    const { data: res } = await api<GetArticleBySlugApiResponse>({
      method: 'GET',
      url: `${SERVICE_PATH}/${slug}`,
    });

    const { data: queryArticle } = res;

    const article = {
      ...queryArticle,
      createdAt: new Date(queryArticle.createdAt),
      updatedAt: new Date(queryArticle.updatedAt),
      publishedAt: new Date(queryArticle.publishedAt),
      ...(queryArticle.author
        ? {
            author: {
              ...queryArticle.author,
              ...(queryArticle.author.avatar
                ? {
                    avatar: {
                      ...queryArticle.author.avatar,
                      createdAt: new Date(queryArticle.author.avatar.createdAt),
                      updatedAt: new Date(queryArticle.author.avatar.updatedAt),
                    },
                  }
                : {}),
              createdAt: new Date(queryArticle.author.createdAt),
              updatedAt: new Date(queryArticle.author.updatedAt),
            },
          }
        : {}),

      ...(queryArticle.type
        ? {
            type: {
              ...queryArticle.type,
              slug:
                ARTICLE_SLUGS.find((slug) => slug.name === queryArticle.type.name)?.slug ?? null,
              createdAt: new Date(queryArticle.type.createdAt),
              updatedAt: new Date(queryArticle.type.updatedAt),
              publishedAt: new Date(queryArticle.type.publishedAt),
            },
          }
        : {}),

      ...(queryArticle.cover
        ? {
            cover: {
              ...queryArticle.cover,
              createdAt: new Date(queryArticle.cover.createdAt),
              updatedAt: new Date(queryArticle.cover.updatedAt),
            },
          }
        : {}),

      ...(queryArticle.collection
        ? {
            collection: {
              ...queryArticle.collection,
              createdAt: new Date(queryArticle.collection.createdAt),
              updatedAt: new Date(queryArticle.collection.updatedAt),
              publishedAt: new Date(queryArticle.collection.publishedAt),

              reports: queryArticle.collection.reports.map((report) => ({
                ...report,
                createdAt: new Date(report.createdAt),
              })),
            },
          }
        : {}),

      // content: queryArticle.content?.map((content) => {
      //   const { id, __component, tab } = content;

      //   console.log(content, 'cjecl');
      //   switch (__component) {
      //     case `${CONTENT_PREFIX}.rich-text`:
      //       return {
      //         id,
      //         __component,
      //         tab,
      //         content: (content as any).content,
      //       } as ContentRichText;

      //     case `${CONTENT_PREFIX}.quote`:
      //       return {
      //         id,
      //         __component,
      //         content: (content as any).content,
      //         tab,
      //         quote: (content as any).quote,
      //         quoter: (content as any).quoter,
      //       } as ContentQuote;

      //     case `${CONTENT_PREFIX}.image`:
      //       return {
      //         id,
      //         __component,
      //         content: (content as any).content,
      //         tab,
      //         caption: (content as any).caption,
      //         fullWidth: (content as any).fullWidth,
      //         image: {
      //           ...(content as any).image,
      //           createdAt: new Date((content as any).image.createdAt),
      //           updatedAt: new Date((content as any).image.updatedAt),
      //         },
      //       } as ContentImage;

      //     case `${CONTENT_PREFIX}.gallery`:
      //       return {
      //         id,
      //         __component,
      //         content: (content as any).content,
      //         type: (content as any).type,
      //         tab,
      //         caption: (content as any).caption,
      //         fullWidth: (content as any).fullWidth,
      //         images: (content as any).images.map((image: any) => ({
      //           ...image,
      //           createdAt: new Date(image.createdAt),
      //           updatedAt: new Date(image.updatedAt),
      //         })),
      //       } as ContentGallery;

      //     default:
      //       return content;
      //   }
      // }),
    } as ArticleDetail;

    return article;
  } catch (error) {
    console.error('`getArticleBySlug` API Error', error);

    return null;
  }
};

export const getArticleSavedLists = async (articleId: Article['id'], token: string) => {
  try {
    const { data } = await publicApi<GetArticleSavedListsApiResponse>({
      method: 'GET',
      url: `${SERVICE_PATH}/${articleId}/saved-lists`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data.map(
      (savedList) =>
        ({
          ...savedList,
          createdAt: new Date(savedList.createdAt),
          updatedAt: new Date(savedList.updatedAt),
        }) as SavedList,
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('`getArticleSavedLists` API Error', error);

    return null;
  }
};

export const getPhotoSavedLists = async (photoId: Photo['id'], token: string) => {
  try {
    const { data } = await publicApi<GetArticleSavedListsApiResponse>({
      method: 'GET',
      url: `/photos/${photoId}/saved-lists`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data.map(
      (savedList) =>
        ({
          ...savedList,
          createdAt: new Date(savedList.createdAt),
          updatedAt: new Date(savedList.updatedAt),
        }) as SavedList,
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('`getArticleSavedLists` API Error', error);

    return null;
  }
};

export const createArticle = async ({ data, token }: { data: ArticleProps; token: string }) => {
  try {
    const { data: response } = await authApi(token)({
      method: 'POST',
      url: SERVICE_PATH,
      data: { ...CREATE_REQUIRE_DEFAULT, ...data },
    });

    return response;
  } catch (error) {
    console.error('`createArticle` API Error', error);

    throw error;
  }
};

export const updateArticle = async ({ data, token }: { data: ArticleProps; token: string }) => {
  try {
    const { data: response } = await authApi(token)({
      method: 'PUT',
      url: `${SERVICE_PATH}/${data.id}`,
      data: { ...CREATE_REQUIRE_DEFAULT, ...data },
    });

    return response;
  } catch (error) {
    console.error('`updateArticle` API Error', error);

    throw error;
  }
};

export const getArticleWithDraftBySlug = async (slug: string) => {
  try {
    const { data: res } = await publicApi<GetArticleBySlugApiResponse>({
      method: 'GET',
      url: `${SERVICE_PATH}/${slug}?_withDraft=true`,
    });

    // console.log('API Response:', {
    //   rawResponse: res,
    //   queryArticle: res.data,
    // });

    const { data: queryArticle } = res;

    const article = {
      ...queryArticle,
      createdAt: new Date(queryArticle.createdAt),
      updatedAt: new Date(queryArticle.updatedAt),
      publishedAt: new Date(queryArticle.publishedAt),
      ...(queryArticle.author
        ? {
            author: {
              ...queryArticle.author,
              ...(queryArticle.author.avatar
                ? {
                    avatar: {
                      ...queryArticle.author.avatar,
                      createdAt: new Date(queryArticle.author.avatar.createdAt),
                      updatedAt: new Date(queryArticle.author.avatar.updatedAt),
                    },
                  }
                : {}),
              createdAt: new Date(queryArticle.author.createdAt),
              updatedAt: new Date(queryArticle.author.updatedAt),
            },
          }
        : {}),

      ...(queryArticle.type
        ? {
            type: {
              ...queryArticle.type,
              slug:
                ARTICLE_SLUGS.find((slug) => slug.name === queryArticle.type.name)?.slug ?? null,
              createdAt: new Date(queryArticle.type.createdAt),
              updatedAt: new Date(queryArticle.type.updatedAt),
              publishedAt: new Date(queryArticle.type.publishedAt),
            },
          }
        : {}),

      ...(queryArticle.cover
        ? {
            cover: {
              ...queryArticle.cover,
              createdAt: new Date(queryArticle.cover.createdAt),
              updatedAt: new Date(queryArticle.cover.updatedAt),
            },
          }
        : {}),

      ...(queryArticle.collection
        ? {
            collection: {
              ...queryArticle.collection,
              createdAt: new Date(queryArticle.collection.createdAt),
              updatedAt: new Date(queryArticle.collection.updatedAt),
              publishedAt: new Date(queryArticle.collection.publishedAt),

              reports: queryArticle.collection.reports.map((report) => ({
                ...report,
                createdAt: new Date(report.createdAt),
              })),
            },
          }
        : {}),

      content: queryArticle.content?.map((content) => {
        const { id, __component, tab } = content;

        switch (__component) {
          case `${CONTENT_PREFIX}.rich-text`:
            return {
              id,
              __component,
              tab,
              content: (content as any).content,
            } as ContentRichText;

          case `${CONTENT_PREFIX}.quote`:
            return {
              id,
              __component,
              content: (content as any).content,
              tab,
              quote: (content as any).quote,
              quoter: (content as any).quoter,
            } as ContentQuote;

          case `${CONTENT_PREFIX}.image`:
            return {
              id,
              __component,
              content: (content as any).content,
              tab,
              caption: (content as any).caption,
              fullWidth: (content as any).fullWidth,
              image: {
                ...(content as any).image,
                createdAt: new Date((content as any).image.createdAt),
                updatedAt: new Date((content as any).image.updatedAt),
              },
            } as ContentImage;

          case `${CONTENT_PREFIX}.gallery`:
            return {
              id,
              __component,
              content: (content as any).content,
              type: (content as any).type,
              tab,
              caption: (content as any).caption,
              fullWidth: (content as any).fullWidth,
              images: (content as any).images.map((image: any) => ({
                ...image,
                createdAt: new Date(image.createdAt),
                updatedAt: new Date(image.updatedAt),
              })),
            } as ContentGallery;

          default:
            return content;
        }
      }),
    } as ArticleDetail;

    return article;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('`getArticleBySlug` API Error', error);

    return null;
  }
};

export const getArticleWithDraftById = async (id: number) => {
  try {
    const { data: res } = await publicApi<GetArticleWithDraftByIdApiResponse>({
      method: 'GET',
      url: `${SERVICE_PATH}/${id}?_withDraft=true`,
    });

    // console.log('API Response:', {
    //   rawResponse: res,
    //   queryArticle: res.data,
    // });

    const { data: queryArticle } = res;

    const article = {
      ...queryArticle,
      createdAt: new Date(queryArticle.createdAt),
      updatedAt: new Date(queryArticle.updatedAt),
      publishedAt: new Date(queryArticle.publishedAt),
      ...(queryArticle.author
        ? {
            author: {
              ...queryArticle.author,
              ...(queryArticle.author.avatar
                ? {
                    avatar: {
                      ...queryArticle.author.avatar,
                      createdAt: new Date(queryArticle.author.avatar.createdAt),
                      updatedAt: new Date(queryArticle.author.avatar.updatedAt),
                    },
                  }
                : {}),
              createdAt: new Date(queryArticle.author.createdAt),
              updatedAt: new Date(queryArticle.author.updatedAt),
            },
          }
        : {}),

      ...(queryArticle.type
        ? {
            type: {
              ...queryArticle.type,
              slug:
                ARTICLE_SLUGS.find((slug) => slug.name === queryArticle.type.name)?.slug ?? null,
              createdAt: new Date(queryArticle.type.createdAt),
              updatedAt: new Date(queryArticle.type.updatedAt),
              publishedAt: new Date(queryArticle.type.publishedAt),
            },
          }
        : {}),

      ...(queryArticle.cover
        ? {
            cover: {
              ...queryArticle.cover,
              createdAt: new Date(queryArticle.cover.createdAt),
              updatedAt: new Date(queryArticle.cover.updatedAt),
            },
          }
        : {}),

      ...(queryArticle.collection
        ? {
            collection: {
              ...queryArticle.collection,
              createdAt: new Date(queryArticle.collection.createdAt),
              updatedAt: new Date(queryArticle.collection.updatedAt),
              publishedAt: new Date(queryArticle.collection.publishedAt),

              reports: queryArticle.collection.reports.map((report) => ({
                ...report,
                createdAt: new Date(report.createdAt),
              })),
            },
          }
        : {}),

      content: queryArticle.content?.map((content) => {
        const { id, __component, tab } = content;

        switch (__component) {
          case `${CONTENT_PREFIX}.rich-text`:
            return {
              id,
              __component,
              tab,
              content: (content as any).content,
            } as ContentRichText;

          case `${CONTENT_PREFIX}.quote`:
            return {
              id,
              __component,
              content: (content as any).content,
              tab,
              quote: (content as any).quote,
              quoter: (content as any).quoter,
            } as ContentQuote;

          case `${CONTENT_PREFIX}.image`:
            return {
              id,
              __component,
              content: (content as any).content,
              tab,
              caption: (content as any).caption,
              fullWidth: (content as any).fullWidth,
              image: {
                ...(content as any).image,
                createdAt: new Date((content as any).image.createdAt),
                updatedAt: new Date((content as any).image.updatedAt),
              },
            } as ContentImage;

          case `${CONTENT_PREFIX}.gallery`:
            return {
              id,
              __component,
              content: (content as any).content,
              type: (content as any).type,
              tab,
              caption: (content as any).caption,
              fullWidth: (content as any).fullWidth,
              images: (content as any).images.map((image: any) => ({
                ...image,
                createdAt: new Date(image.createdAt),
                updatedAt: new Date(image.updatedAt),
              })),
            } as ContentGallery;

          default:
            return content;
        }
      }),
    } as ArticleDetail;

    return article;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('`getArticleWithDraftById` API Error', error);

    return null;
  }
};

export const deleteArticle = async ({ articleId, token }: { articleId: string; token: string }) =>
  await authApi(token)({
    method: 'DELETE',
    url: `${SERVICE_PATH}/${articleId}`,
  });

export const increaseArticleShares = async (articleId: number) => {
  try {
    await publicApi({
      method: 'POST',
      url: `${SERVICE_PATH}/${articleId}/increase-share`,
    });
  } catch (error) {
    console.error('`increaseArticleShares` API Error', error);
  }
};

// Add these interfaces to the existing file
interface AuthorListResponse {
  results: Author[];
  pagination: {
    page: number;
    pageSize: number;
    pageCount: number;
    total: number;
  };
}

interface Author {
  id: number;
  username: string;
  email: string;
  provider: string;
  confirmed: boolean;
  blocked: boolean;
  createdAt: string;
  updatedAt: string;
  name: string;
  bio: string | null;
  gender: string | null;
  drive: string | null;
  location: string | null;
  avatar?: {
    id: number;
    name: string;
    url: string;
    createdAt: string;
    updatedAt: string;
  };
}

export const getArticleAuthors = async ({
  params,
  token,
}: {
  params?: {
    page?: number;
    pageSize?: number;
    _q?: string;
    _filter?: string;
  };
  token: string;
}) => {
  try {
    const { data } = await authApi(token)({
      method: 'GET',
      url: `/content-manager/relations/api::article.article/author`,
      params: {
        pageSize: params?.pageSize || 10,
        page: params?.page || 1,
        _q: params?._q || '',
        _filter: params?._filter || '$containsi',
      },
    });

    return {
      authors: data.results.map((author: Author) => ({
        ...author,
        createdAt: new Date(author.createdAt),
        updatedAt: new Date(author.updatedAt),
        ...(author.avatar
          ? {
              avatar: {
                ...author.avatar,
                createdAt: new Date(author.avatar.createdAt),
                updatedAt: new Date(author.avatar.updatedAt),
              },
            }
          : {}),
      })),
      pagination: data.pagination,
    };
  } catch (error) {
    console.error('`getArticleAuthors` API Error', error);

    return null;
  }
};
