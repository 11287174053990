import {
  ImageSquare,
  ListBullets,
  ListNumbers,
  Minus,
  Quotes,
  SquareSplitHorizontal,
  Table,
  TextHOne,
  TextHThree,
  TextHTwo,
  TextT,
  VideoCamera,
} from '@phosphor-icons/react/dist/ssr';
import { Hash } from '@phosphor-icons/react';

import { Group } from './types';

export const GROUPS: Group[] = [
  {
    name: 'format',
    title: 'Format',
    commands: [
      {
        name: 'text',
        label: 'Text',
        icon: TextT,
        aliases: ['p'],
        action: (editor) => {
          editor.chain().focus().setParagraph().run();
        },
      },
      {
        name: 'heading1',
        label: 'Heading 1',
        icon: TextHOne,
        aliases: ['h1'],
        action: (editor) => {
          editor.chain().focus().setHeading({ level: 1 }).run();
        },
      },
      {
        name: 'heading2',
        label: 'Heading 2',
        icon: TextHTwo,
        aliases: ['h2'],
        action: (editor) => {
          editor.chain().focus().setHeading({ level: 2 }).run();
        },
      },
      {
        name: 'heading3',
        label: 'Heading 3',
        icon: TextHThree,
        aliases: ['h3'],
        action: (editor) => {
          editor.chain().focus().setHeading({ level: 3 }).run();
        },
      },
      {
        name: 'bulletList',
        label: 'Bullet List',
        icon: ListBullets,
        aliases: ['ul'],
        action: (editor) => {
          editor.chain().focus().toggleBulletList().run();
        },
      },
      {
        name: 'numberedList',
        label: 'Numbered List',
        icon: ListNumbers,
        aliases: ['ol'],
        action: (editor) => {
          editor.chain().focus().toggleOrderedList().run();
        },
      },
      {
        name: 'quote',
        label: 'Quote',
        icon: Quotes,
        action: (editor) => {
          editor.chain().focus().setBlockquote().run();
        },
      },
    ],
  },
  {
    name: 'insert',
    title: 'Insert',
    commands: [
      {
        name: 'table',
        label: 'Table',
        icon: Table,
        action: (editor) => {
          editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: false }).run();
        },
      },
      {
        name: 'image',
        label: 'Image',
        icon: ImageSquare,
        aliases: ['img'],
        action: (editor) => {
          editor.chain().focus().setImageUpload().run();
        },
      },
      {
        name: 'image',
        label: 'Gallery code',
        icon: Hash,
        aliases: ['img'],
        action: (editor) => {
          editor.chain().focus().setGalleryUpload().run();
        },
      },
      {
        name: 'columns',
        label: 'Columns',
        icon: SquareSplitHorizontal,
        aliases: ['cols'],
        action: (editor) => {
          editor
            .chain()
            .focus()
            .setColumns()
            .focus(editor.state.selection.head - 1)
            .run();
        },
      },
      {
        name: 'horizontalRule',
        label: 'Divider',
        icon: Minus,
        aliases: ['hr'],
        action: (editor) => {
          editor.chain().focus().setHorizontalRule().run();
        },
      },
    ],
  },
  {
    name: 'embed',
    title: 'Embed',
    commands: [
      {
        name: 'youtube',
        label: 'YouTube',
        icon: VideoCamera,
        action: (editor) => {
          editor.chain().focus().setEmbedEditor().run();
        },
      },
    ],
  },
];
