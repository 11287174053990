'use client';

import React, { Fragment, useMemo, useState } from 'react';
import { cn } from '@nextui-org/theme';
import { AnimatePresence, motion, useMotionValueEvent, useScroll } from 'framer-motion';

import { Tabs } from '@/components';
import { Content } from '@/models/article';
import { articleRenderer } from '@/utils/article-renderer';

interface ArticleContentProps {
  contents: Content[];
  className?: string;
  classNames?: {
    section: string;
  };
}

const sortContentByTab = (contents: Content[]) => {
  return Object.values(
    contents.reduce(
      (acc: Record<string, { tab: string; contents: Content[] }>, { tab, ...content }) => {
        if (!tab) return acc;
        if (!acc[tab]) acc[tab] = { tab, contents: [] };

        acc[tab].contents.push({ tab, ...content });

        return acc;
      },
      {},
    ),
  );
};

const ArticleContent: React.FC<ArticleContentProps> = ({ contents, className, classNames }) => {
  const sortedContent = useMemo(() => sortContentByTab(contents), [contents]);
  const tabs = useMemo(
    () => sortedContent.map(({ tab }) => ({ key: tab, title: tab })),
    [contents],
  );

  const [currentTabKey, setCurrentTabKey] = useState(tabs[0]?.key);

  const currentTabContent = useMemo(
    () => sortedContent.find(({ tab }) => tab === currentTabKey),
    [sortedContent, currentTabKey],
  );

  // console.log(contents, 'check123');

  const { scrollYProgress } = useScroll();

  const [visible, setVisible] = useState(true);

  useMotionValueEvent(scrollYProgress, 'change', (current) => {
    // Check if current is not undefined and is a number
    if (typeof current === 'number') {
      let direction = current! - scrollYProgress.getPrevious()!;

      if (scrollYProgress.get() < 0.1) {
        setVisible(true);
      } else {
        if (direction < 0) {
          setVisible(true);
        } else {
          setVisible(false);
        }
      }
    }
  });

  return (
    <>
      {tabs.length > 0 && (
        <AnimatePresence mode='wait'>
          <motion.div
            animate={{
              y: visible ? 0 : '-4rem',
              opacity: visible ? 1 : 1,
            }}
            className='sticky top-8 z-20 flex h-28 w-full flex-col items-center justify-end border-b-1 border-b-gray-100 bg-neutral-white'
            transition={{ duration: 0.2 }}
          >
            <Tabs
              className='w-full max-w-4xl p-0 capitalize'
              classNames={{ tabList: 'p-0' }}
              items={tabs}
              selectedKey={currentTabKey}
              onSelectionChange={(key) => setCurrentTabKey(key as string)}
            />
          </motion.div>
        </AnimatePresence>
      )}

      <article className={cn('mt-12 flex max-w-4xl flex-col gap-10', className)}>
        {tabs.length > 0 &&
          currentTabContent?.contents.map((section) => (
            <Fragment key={section.id}>{articleRenderer(section)}</Fragment>
          ))}

        {tabs.length <= 0 &&
          contents.map((section) => (
            <Fragment key={section.id}>{articleRenderer(section, classNames?.section)}</Fragment>
          ))}
      </article>
    </>
  );
};

export default ArticleContent;
