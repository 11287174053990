'use client';

import { Skeleton } from '@nextui-org/skeleton';
import { EditorContent, EditorContentProps, useEditor } from '@tiptap/react';
import { useDebounce } from '@uidotdev/usehooks';
import React, { useEffect } from 'react';

import 'tippy.js/animations/scale-subtle.css';

import { ExtensionKit } from './extensions/extension-kit';
import DragHandle from './menus/DragHandle/DragHandle';
import ImageMenu from './menus/ImageMenu/ImageMenu';
import LinkMenu from './menus/LinkMenu/LinkMenu';
import { TableColumnMenu, TableRowMenu } from './menus/TableMenu';
import TextMenu from './menus/TextMenu/TextMenu';
import ColumnsMenu from './menus/ColumnsMenu/ColumnsMenu';
import './styles/block.css';
import './styles/placeholder.css';
import './styles/table.css';
import './styles/typography.css';

interface EditorProps extends Omit<EditorContentProps, 'ref' | 'editor' | 'content'> {
  content?: string;
  setContent: (htmlContent: string) => void;
  currentTabId: number;
}

const Editor: React.FC<EditorProps> = ({ content, setContent, currentTabId, ...others }) => {
  const menuContainerRef = React.useRef(null);

  const [hydrated, setHydrated] = React.useState<boolean>(false);
  const debouncedContent = useDebounce(content, 1000);

  const editor = useEditor({
    extensions: ExtensionKit,
    editorProps: {
      attributes: {
        class: 'outline-none container h-full max-w-5xl px-20 pb-20',
      },
    },
    onUpdate: ({ editor }) => {
      setContent(editor.getHTML());
    },
    immediatelyRender: false,
    content: debouncedContent,
  });

  useEffect(() => {
    editor?.commands.setContent(content ?? {});
  }, [currentTabId]);

  // Hydrate the editor
  useEffect(() => {
    if (editor && !hydrated) {
      setHydrated(true);
    }
  }, [editor, hydrated]);

  // console.log('@aloha', content, 'content 123');
  // console.log(content, '@check');

  return (
    <div ref={menuContainerRef} className='h-full w-full'>
      {hydrated && editor && (
        <>
          <DragHandle editor={editor} />
          <LinkMenu appendTo={menuContainerRef} editor={editor} />
          <ImageMenu appendTo={menuContainerRef} editor={editor} />
          <TextMenu editor={editor} />
          <TableRowMenu appendTo={menuContainerRef} editor={editor} />
          <TableColumnMenu appendTo={menuContainerRef} editor={editor} />
          <ColumnsMenu appendTo={menuContainerRef} editor={editor} />

          <EditorContent {...others} editor={editor} />
        </>
      )}

      {!hydrated && (
        <div className='container flex w-full max-w-5xl flex-col gap-2 px-20'>
          <Skeleton className='my-2 h-5 w-full rounded-none' />
          <Skeleton className='my-2 h-5 w-full rounded-none' />
          <Skeleton className='my-2 h-5 w-3/4 rounded-none' />
        </div>
      )}
    </div>
  );
};

export default Editor;
