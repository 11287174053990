'use client';

import React, { useEffect, useState } from 'react';
import { useParams } from 'next/navigation';
import { ArrowSquareOut } from '@phosphor-icons/react';

import { heading } from '@/theme/typography';

const ArticleCommentXF = () => {
  const params = useParams();
  const [pBodyMainHeight, setPBodyMainHeight] = useState(null);

  const url = `https://xenforo.bimmerhead.net/index.php?threads/cms-${params.slug}/`;

  const openUrl = () => {
    window.open(url, '_blank');
  };

  useEffect(() => {
    // Listen for messages from the iframe
    const handleMessage = (event: any) => {
      // Ensure the message is from the expected origin
      if (event.origin === 'https://xenforo.bimmerhead.net') {
        const { pBodyMainHeight } = event.data;

        if (pBodyMainHeight) {
          setPBodyMainHeight(pBodyMainHeight);
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex items-center justify-between'>
        <h2 className={heading({ size: 'h5' })}>Comments</h2>
        <div className='flex items-center gap-2'>
          <span className='text-sm'>View Forum</span>
          <ArrowSquareOut size={24} style={{ cursor: 'pointer' }} onClick={openUrl} />
        </div>
      </div>

      <iframe
        allow='fullscreen'
        src={url + '&in_iframe=1'}
        style={{
          width: '100%',
          border: 'none',
          minHeight: '300px',
          // maxHeight: '100vh',
          height: pBodyMainHeight ? `${pBodyMainHeight}px` : '300px',
          borderRadius: '10px',
        }}
        title='Comment Input'
        width='100%'
      />
    </div>
  );
};

export default ArticleCommentXF;
