'use client';

import { cn } from '@nextui-org/theme';
import { useRef, useState, useEffect, SetStateAction, Dispatch } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Hash, ImageSquare, X } from '@phosphor-icons/react';
import { Button } from '@nextui-org/button';
import { Divider } from '@nextui-org/divider';
import { useDisclosure } from '@nextui-org/modal';

import GalleryCarousel from './GalleryCarousel';
import { GalleryCodeModal } from './GalleryCodeModal';
import { EditGalleryModal } from './EditGalleryModal';

import { body, heading, paragraph } from '@/theme/typography';
import useGalleryShowCaseStore from '@/hooks/useGalleryShowCaseStore';
import { Photo } from '@/types/apis/model';

interface UploadGalleryProps {
  onFileSelect?: (files: File[]) => void;
  images: Slide[];
  setImages?: Dispatch<SetStateAction<Slide[]>>;
  layout: 'carousel' | 'thumbnail' | 'grid';
  setLayout?: Dispatch<SetStateAction<'carousel' | 'thumbnail' | 'grid'>>;
  isPreviewOnly?: boolean;
}

export interface Slide {
  id: string;
  image: {
    url: string;
  };
  file?: File;
}

export function UploadGallery({
  onFileSelect,
  images,
  setImages,
  layout,
  isPreviewOnly = false,
  setLayout,
}: UploadGalleryProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  // const [images, setImages] = useState<Slide[]>([]);
  const [imageStore, setImageStore] = useState<Slide[]>(images);

  const [confirmedPhotos, setConfirmedPhotos] = useState<Photo[]>([]);
  const {
    isOpen: isGalleryCodeModalOpen,
    onOpen: onGalleryCodeModalOpen,
    onClose: onGalleryCodeModalClose,
  } = useDisclosure();

  const {
    isOpen: isEditGalleryModalOpen,
    onOpen: onEditGalleryModalOpen,
    onClose: onEditGalleryModalClose,
  } = useDisclosure();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, isEdit = false) => {
    const files = Array.from(event.target.files || []);

    if (files.length > 0) {
      const newSlides: Slide[] = files.map((file, index) => ({
        id: (Date.now() + index).toString(),
        image: {
          url: URL.createObjectURL(file),
        },
      }));

      if (!isEdit) {
        setImages?.((prevImages) => [...prevImages, ...newSlides]);
      } else {
        setImageStore((prevImages) => [...prevImages, ...newSlides]);
      }
      if (onFileSelect) {
        onFileSelect(files);
      }
    }
  };

  // console.log(images, 'check');

  const { isGalleryShowCaseVisible } = useGalleryShowCaseStore();

  // useEffect(() => {
  //   return () => {
  //     images.forEach((image) => {
  //       URL.revokeObjectURL(image.image.url);
  //     });
  //   };
  // }, [images]);

  useEffect(() => {
    if (confirmedPhotos && confirmedPhotos.length > 0) {
      const newSlides: Slide[] = confirmedPhotos.map((photo, index) => ({
        ...photo,
        id: (Date.now() + index).toString(),
      }));

      setImages?.(newSlides);
    }
  }, [confirmedPhotos]);

  // console.log(images, 'check image store');

  return (
    <AnimatePresence>
      {isGalleryShowCaseVisible && (
        <motion.div
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -50, opacity: 0 }}
          initial={{ y: -50, opacity: 0 }}
          transition={{ duration: 0.3, ease: 'easeOut' }}
        >
          {images.length > 0 && (
            <div>
              <div
                className={cn({
                  'border-2 border-primary bg-blue-900 p-6': layout === 'grid',
                  'border-[3px] border-primary': layout === 'carousel',
                })}
              >
                <GalleryCarousel
                  isPreviewOnly={isPreviewOnly}
                  layout={'thumbnail'}
                  slides={images}
                />
              </div>
              {!isPreviewOnly && (
                <div className='my-6 flex w-full flex-row items-center justify-center'>
                  <div className='flex gap-[0.12rem] border border-gray-50 bg-white-50 p-[0.31rem]'>
                    {/* <Button
                      isIconOnly
                      radius='none'
                      variant={'light'}
                      onClick={() => setLayout?.('carousel')}
                    >
                      <FocusSvg
                        className={cn('[&>path]:fill-gray-800', {
                          '[&>path]:fill-primary': layout === 'carousel',
                        })}
                      />
                    </Button>
                    <Button
                      isIconOnly
                      radius='none'
                      variant={'light'}
                      onClick={() => setLayout?.('thumbnail')}
                    >
                      <ThumbnailSvg
                        className={cn('[&>path]:fill-gray-800', {
                          '[&>path]:fill-primary': layout === 'thumbnail',
                        })}
                      />
                    </Button>
                    <Button
                      isIconOnly
                      radius='none'
                      variant={'light'}
                      onClick={() => setLayout?.('grid')}
                    >
                      <GridSvg
                        className={cn('[&>path]:fill-gray-800', {
                          '[&>path]:fill-primary': layout === 'grid',
                        })}
                      />
                    </Button> */}

                    {/* <Divider className='h-10 bg-gray-50' orientation='vertical' /> */}
                    <Button
                      isIconOnly
                      radius='none'
                      variant={'light'}
                      onClick={() => {
                        onEditGalleryModalOpen();
                        setImageStore(images);
                      }}
                    >
                      Edit
                    </Button>
                    <Divider className='h-10 bg-gray-50' orientation='vertical' />

                    <Button
                      isIconOnly
                      className='bg-transparent text-gray-800'
                      radius='none'
                      variant='light'
                      onClick={() => setImages?.([])}
                    >
                      <X size={20} />
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
          {images.length === 0 && (
            <div
              className={cn(
                'flex flex-col items-center justify-center gap-9 border border-gray-100 bg-neutral-white p-12',
              )}
            >
              <div className='flex flex-col items-center gap-0.5 text-center'>
                <h1 className={cn(heading({ size: 'h5', weight: 'medium' }))}>Upload carousel</h1>
                <p className={cn(paragraph(), 'px-8')}>
                  Upload an image file, pick one from your album library, or add one with gallery
                  code.
                </p>
              </div>

              <div className='flex flex-row items-center gap-6'>
                {[
                  {
                    key: 'upload',
                    title: 'Upload images',
                    icon: ImageSquare,
                    onClick: () => fileInputRef.current?.click(),
                  },
                  {
                    key: 'gallery',
                    title: 'Gallery code',
                    icon: Hash,
                    onClick: onGalleryCodeModalOpen,
                  },
                ].map(({ key, title, icon: Icon, onClick }) => (
                  <button
                    key={key}
                    className='flex flex-col items-center gap-3'
                    type='button'
                    onClick={onClick}
                  >
                    <div className='flex h-14 w-14 items-center justify-center rounded-full border-1.5 border-primary text-primary'>
                      <Icon size={24} />
                    </div>
                    <span className={cn(body({ weight: 'medium' }), 'text-nowrap')}>{title}</span>
                  </button>
                ))}
              </div>

              <input
                ref={fileInputRef}
                multiple
                accept='.jpg,.jpeg,.png,.webp,.gif'
                aria-label='Upload images'
                className='h-0 w-0 overflow-hidden opacity-0'
                type='file'
                onChange={handleFileChange}
              />
            </div>
          )}
          <GalleryCodeModal
            // confirmedPhotos={confirmedPhotos}
            isOpen={isGalleryCodeModalOpen}
            setConfirmedPhotos={setConfirmedPhotos}
            onClose={onGalleryCodeModalClose}
          />
          <EditGalleryModal
            handleFileChange={handleFileChange}
            imageStore={imageStore}
            isOpen={isEditGalleryModalOpen}
            setImageStore={setImageStore}
            setImages={setImages!} // Add non-null assertion since setImages is required
            onClose={onEditGalleryModalClose}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
}
