'use client';

import { cn } from '@nextui-org/theme';
import { Hash, ImageSquare } from '@phosphor-icons/react/dist/ssr';
import { Editor, NodeViewWrapper } from '@tiptap/react';
import React, { useState, useEffect } from 'react';
import { useDisclosure } from '@nextui-org/modal';
import { useSession } from 'next-auth/react';
import { Spinner } from '@nextui-org/spinner';

import { body, heading, paragraph } from '@/theme/typography';
import { GalleryCodeModal } from '@/app/publish/article/_components/ImageGallery/GalleryCodeModal';
import { Photo } from '@/types/apis/model';
import { upload } from '@/services/apis/upload';
import { Image } from '@/models/image';

interface ImageUploadViewProps {
  getPos: () => number;
  editor: Editor;
}

async function createFileFromUrl(url: string, filename: string): Promise<File> {
  const response = await fetch(url);
  const blob = await response.blob();

  return new File([blob], filename, { type: 'image/jpeg' });
}

export const ImageUploadView: React.FC<ImageUploadViewProps> = ({ getPos, editor }) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [confirmedPhotos, setConfirmedPhotos] = useState<Photo[]>([]);
  const { data: session } = useSession();
  const {
    isOpen: isGalleryCodeModalOpen,
    onOpen: onGalleryCodeModalOpen,
    onClose: onGalleryCodeModalClose,
  } = useDisclosure();
  const [isUploading, setIsUploading] = useState(false);

  const handleFileChange = React.useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files || e.target.files.length <= 0) return;

      setIsUploading(true);
      try {
        if (e.target?.files?.length === 1) {
          editor
            .chain()
            .setImageBlock({
              src: URL.createObjectURL(e.target.files[0]),
            })
            .deleteRange({ from: getPos(), to: getPos() })
            .focus()
            .run();
        } else if (e.target?.files?.length > 1) {
          const urls = Array.from(e.target.files).map((file) => URL.createObjectURL(file));

          const galleryFiles = await Promise.all(
            urls.map((image, index) =>
              createFileFromUrl(image, `gallery-image-${Date.now()}-${index}.jpg`),
            ),
          );

          const galleryFormData = new FormData();

          galleryFormData.append('ref', 'plugin::users-permissions.user');
          galleryFormData.append('refId', session?.user.id!.toString() ?? '');
          galleryFormData.append('field', 'gallery');
          galleryFiles.forEach((file) => galleryFormData.append('files', file));

          const galleryUploadResponse = await upload({
            data: galleryFormData,
            token: session?.token ?? '',
          });

          const galleryUrls = galleryUploadResponse?.data.map(
            (image: Image) => image.url,
          ) as string[];

          editor
            .chain()
            .setImageGrid({ urls: galleryUrls })
            .deleteRange({ from: getPos(), to: getPos() })
            .focus()
            .run();
        }
      } catch (error) {
        console.error('Upload failed:', error);
      } finally {
        setIsUploading(false);
      }
    },
    [getPos, editor, session],
  );

  useEffect(() => {
    if (confirmedPhotos.length === 1) {
      editor
        .chain()
        .setImageBlock({
          src: confirmedPhotos[0].image.url,
        })
        .deleteRange({ from: getPos(), to: getPos() })
        .focus()
        .run();
    } else if (confirmedPhotos.length > 1) {
      editor
        .chain()
        .setImageGrid({ urls: confirmedPhotos.map((photo) => photo.image.url) })
        .deleteRange({ from: getPos(), to: getPos() })
        .focus()
        .run();
    }
  }, [confirmedPhotos, editor, getPos]);

  return (
    <NodeViewWrapper>
      <div
        data-drag-handle
        className={cn(
          'flex flex-col items-center justify-center gap-9 border border-gray-100 bg-neutral-white p-12',
          'relative',
        )}
        contentEditable={false}
      >
        {isUploading && (
          <>
            <div className='bg-white/80 absolute inset-0 backdrop-blur-sm' />
            <div className='absolute inset-0 z-10 flex items-center justify-center'>
              <Spinner color='primary' size='lg' />
            </div>
          </>
        )}
        <div className='flex flex-col items-center gap-0.5 text-center'>
          <h1 className={cn(heading({ size: 'h5', weight: 'medium' }))}>Upload image</h1>
          <p className={cn(paragraph(), 'px-8')}>
            Upload an image file, pick one from your album library, insert from URL, or add one with
            gallery code.
          </p>
        </div>

        <div className='flex flex-row items-center gap-6'>
          {[
            {
              key: 'upload',
              title: 'Upload image',
              icon: ImageSquare,
              onClick: () => fileInputRef.current?.click(),
            },
            {
              key: 'gallery',
              title: 'Gallery code',
              icon: Hash,
              onClick: onGalleryCodeModalOpen,
            },
          ].map(({ key, title, icon: Icon, onClick }) => (
            <button key={key} className='flex flex-col items-center gap-3' onClick={onClick}>
              <div className='flex h-14 w-14 items-center justify-center rounded-full border-1.5 border-primary text-primary'>
                <Icon size={24} />
              </div>
              <span className={cn(body({ weight: 'medium' }), 'text-nowrap')}>{title}</span>
            </button>
          ))}
        </div>

        <input
          ref={fileInputRef}
          multiple
          accept='.jpg,.jpeg,.png,.webp,.gif'
          aria-label='Upload image'
          className='h-0 w-0 overflow-hidden opacity-0'
          type='file'
          onChange={handleFileChange}
        />

        <GalleryCodeModal
          isOpen={isGalleryCodeModalOpen}
          onlyAllowSelectOne={false}
          setConfirmedPhotos={setConfirmedPhotos}
          onClose={onGalleryCodeModalClose}
        />
      </div>
    </NodeViewWrapper>
  );
};

export default ImageUploadView;
