import { getUserProfile } from './user';

import { publicApi } from '@/config/api';
import { User } from '@/models/user';
import { SignInApiResponse } from '@/types/apis/auth';
import { GetMeApiResponse } from '@/types/apis/user';

const SERVICE_PATH = '/auth';

export const signIn = async ({ email, password }: { email: string; password: string }) => {
  const res = await publicApi<SignInApiResponse>({
    method: 'POST',
    url: `${SERVICE_PATH}/local`,
    data: {
      identifier: email,
      password,
    },
  });

  // Get additional user profile data
  const userProfile = await getUserProfile(res.data.user.username);

  // Return combined data

  return {
    ...res.data,
    user: {
      ...res.data.user,
      role: userProfile?.profile.role || { type: 'user' }, // Default to 'user' if profile fetch fails
    },
  };
};

export const signUp = async ({
  name,
  username,
  email,
  password,
}: {
  name: string;
  username: string;
  email: string;
  password: string;
}) => {
  const res = await publicApi({
    method: 'POST',
    url: `${SERVICE_PATH}/local/register`,
    data: {
      name,
      username,
      email,
      password,
    },
  });

  return res;
};

export const forgotPassword = async ({ email }: { email: string }) => {
  const res = await publicApi({
    method: 'POST',
    url: `${SERVICE_PATH}/forgot-password`,
    data: { email },
  });

  return res;
};

export const resetPassword = async ({
  password,
  passwordConfirmation,
  code,
}: {
  password: string;
  passwordConfirmation: string;
  code: string;
}) => {
  const res = await publicApi({
    method: 'POST',
    url: `${SERVICE_PATH}/reset-password`,
    data: { password, passwordConfirmation, code },
  });

  return res;
};

export const getMe = async (token: string) => {
  try {
    const { data: res } = await publicApi<GetMeApiResponse>({
      url: '/users/me',
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    });

    const user: User = {
      id: res.id,
      username: res.username,
      name: res.name,
      email: res.email,
      avatar: null,
      blocked: res.blocked,
      provider: res.provider,
      confirmed: res.confirmed,
      gender: res.gender,
      bio: res.bio,
      drive: res.drive,
      location: res.location,
      role: res.role,

      createdAt: new Date(res.createdAt),
      updatedAt: new Date(res.updatedAt),
    };

    return user;
  } catch (error) {
    return null;
  }
};
